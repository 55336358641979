'use client'

import React, { useEffect } from 'react'
import { initMixpanel } from './mixpanelClient'

export default function RootLayout() {
  useEffect(() => {
    initMixpanel()
  }, [])
}
